import React from 'react'
// import React, { useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header'
import Footer from './components/Footer'
  // import "./revolution/css/navigation.css";
  // import "./revolution/css/layers.css";
import "./revolution/css/settings.css";
import "./css/animate.css";
import "./css/bootstrap.min.css";
import "./css/et-line-icons.css";
import "./css/font-awesome.min.css";
import "./css/themify-icons.css";
import "./css/swiper.min.css";
import "./css/justified-gallery.min.css";
import "./css/magnific-popup.css";
import "./css/bootsnav.css";
import "./css/style.css";
import "./css/responsive.css";









import SliderSection from './components/SliderSection';
import About from './components/About';
import Service from './components/Service';
import Portfolio from './components/Portfolio';
import Information from './components/Information';
import Parallax from './components/Parallax';
import StartSection from './components/StartSection';
import Advantage from './components/Advantage';
import Counter from './components/Counter';
import Video from './components/Video';
import ClientsSlider from './components/ClientsSlider';
import TestimonialSlider from './components/TestimonialSlider';
import SecondService from './components/SecondService';
import SecondParallax from './components/SecondParallax';
import LatestBlog from './components/LatestBlog';
import Faq from './components/Faq';
import Contact from './components/Contact';
import HireTalent from './components/HireTalent';
import Intern from './components/Intern';
import JobSearch from './components/JobSearch';
import Login from './components/Login';
const App = () => {
  return (
    <div>
      {/* </> */}
     
     

      <Router>

      {/* <div>hello</div> */}
      {/* <About/> */}
      {/* <Service/> */}
  
      <Routes>

        <Route path="/" element={<Header />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/login" element={<Login />} />
        <Route path="/intern-wth-us" element={<Intern />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/job-search" element={<JobSearch />} />
        <Route path="/hire-talent" element={<HireTalent />} />
        {/* <Route path="/privacy-policy" element={<Privacy />} /> */}
       
      </Routes>
    </Router>
    {/* <Footer/> */}
    </div>
  )
}

export default App
