import React from 'react'
import Navbar from './Navbar'
import Footer from './Footer'

const Intern = () => {
  return (
    <div>
      <Navbar />
      <>
      <section
            className="wow fadeIn parallax"
            data-stellar-background-ratio="0.5"
            style={{ backgroundImage: 'url("http://placehold.it/1920x1100")' }}
          >
            <div className="opacity-medium bg-extra-dark-gray" />
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-sm-12 col-xs-12 extra-small-screen display-table page-title-large">
                  <div className="display-table-cell vertical-align-middle text-center">
                    {/* start page title */}
                    <h1 className="text-white alt-font font-weight-600 letter-spacing-minus-1 margin-15px-bottom">
                     Intern With Us
                    </h1>
                    {/* end page title */}
                    {/* start sub title */}
                    <span className="text-white opacity6 alt-font">
                      Unlimited power and customization possibilities
                    </span>
                    {/* end sub title */}
                  </div>
                </div>
              </div>
            </div>
          </section>
        {/* end page title section */}
        {/* start contact form section */}
        <section className="wow fadeIn big-section" id="section-down">
          <div className="container">
            <div className="row equalize sm-equalize-auto">
              <div className="col-md-6 col-sm-12 col-xs-12 sm-margin-30px-bottom wow fadeInLeft">
                <div className="padding-fifteen-all bg-light-gray border-radius-6 md-padding-seven-all xs-padding-30px-all height-100">
                  <span className="text-extra-dark-gray alt-font text-large font-weight-600 margin-25px-bottom display-block">
                Intern With Us
                  </span>
                  <form id="contact-form" action="javascript:void(0)" method="post">
                    <div>
                      <div id="success-contact-form" className="no-margin-lr" />
                      <input
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Law School*"
                        className="border-radius-4 bg-white medium-input"
                      />
                      <input
                        type="text"
                        name="email"
                        id="email"
                        placeholder="Law School Batch *"
                        className="border-radius-4 bg-white medium-input"
                      />
                      <input
                        type="text"
                        name="subject"
                        id="subject"
                        placeholder="Any Previous Experience"
                        className="border-radius-4 bg-white medium-input"
                      />
                      <textarea
                        name="comment"
                        id="comment"
                        placeholder="Your Message"
                        rows={5}
                        className="border-radius-4 bg-white medium-textarea"
                        defaultValue={""}
                      />
                      <button
                        id="contact-us-button"
                        type="submit"
                        className="btn btn-small border-radius-4 btn-dark-gray"
                      >
                        send message
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-md-6 col-sm-12 col-xs-12 last-paragraph-no-margin wow fadeInRight">
                <div className="padding-ten-all bg-light-gray border-radius-6 md-padding-seven-all xs-padding-30px-all height-100 sm-text-center">
                  <img
                    src="http://placehold.it/1000x700"
                    alt=""
                    className="border-radius-6 margin-35px-bottom xs-margin-30px-bottom"
                  />
                  <span className="text-large font-weight-600 alt-font text-extra-dark-gray margin-5px-bottom display-block">
                    Let's plan for a new project?
                  </span>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and typesetting
                    industry, Lorem Ipsum has been the standard dummy text.
                  </p>
                  <a
                    href="about-us-modern.html"
                    className="btn btn-dark-gray btn-small text-extra-small border-radius-4 margin-25px-top"
                  >
                    About Company
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* end contact form section */}
        {/* start information section */}
    
        
      </>

      <Footer />

    </div>
  )
}

export default Intern
