import React from 'react'
import Navbar from './Navbar'

const Contact = () => {
  return (
    <div>
      <>
        <Navbar />
        {/* end header */}
        {/* start page title section */}

        {/* end page title section */}
        {/* start help section */}

        {/* end help section */}
        {/* start contact section */}

        {/* end contact section */}
        {/* start form section */}
        <>
          <section
            className="wow fadeIn parallax"
            data-stellar-background-ratio="0.5"
            style={{ backgroundImage: 'url("http://placehold.it/1920x1100")' }}
          >
            <div className="opacity-medium bg-extra-dark-gray" />
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-sm-12 col-xs-12 extra-small-screen display-table page-title-large">
                  <div className="display-table-cell vertical-align-middle text-center">
                    {/* start page title */}
                    <h1 className="text-white alt-font font-weight-600 letter-spacing-minus-1 margin-15px-bottom">
                      Contact us classic
                    </h1>
                    {/* end page title */}
                    {/* start sub title */}
                    <span className="text-white opacity6 alt-font">
                      Unlimited power and customization possibilities
                    </span>
                    {/* end sub title */}
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* end page title section */}
          {/* start contact info */}
          <section className="wow fadeIn">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-8 col-xs-12 center-col margin-eight-bottom sm-margin-40px-bottom xs-margin-30px-bottom text-center last-paragraph-no-margin">
                  <h5 className="alt-font font-weight-700 text-extra-dark-gray text-uppercase">
                    We love to talk
                  </h5>
                </div>
              </div>
              <div className="row">
                <div className="row">
                  {/* start contact info item */}
                  <div className="col-md-3 col-sm-6 col-xs-12 text-center sm-margin-eight-bottom xs-margin-30px-bottom wow fadeInUp last-paragraph-no-margin">
                    <div className="display-inline-block margin-20px-bottom">
                      <div className="bg-extra-dark-gray icon-round-medium">
                        <i className="icon-map-pin icon-medium text-white" />
                      </div>
                    </div>
                    <div className="text-extra-dark-gray text-uppercase text-small font-weight-600 alt-font margin-5px-bottom">
                      Visit Our Office
                    </div>
                    <p className="center-col">
                      401 Broadway, 24th Floor
                      <br />
                      New York, NY 10013.
                    </p>
                    <a
                      href="#"
                      className="text-decoration-line-through-deep-pink text-uppercase text-deep-pink text-small margin-15px-top xs-margin-10px-top display-inline-block"
                    >
                      GET DIRECTION
                    </a>
                  </div>
                  {/* end contact info item */}
                  {/* start contact info item */}
                  <div
                    className="col-md-3 col-sm-6 col-xs-12 text-center sm-margin-eight-bottom xs-margin-30px-bottom wow fadeInUp last-paragraph-no-margin"
                    data-wow-delay="0.2s"
                  >
                    <div className="display-inline-block margin-20px-bottom">
                      <div className="bg-extra-dark-gray icon-round-medium">
                        <i className="icon-chat icon-medium text-white" />
                      </div>
                    </div>
                    <div className="text-extra-dark-gray text-uppercase text-small font-weight-600 alt-font margin-5px-bottom">
                      Let's Talk
                    </div>
                    <p className="center-col">
                      Phone: 1-800-222-000
                      <br />
                      Fax: 1-800-222-002
                    </p>
                    <a
                      href="#"
                      className="text-decoration-line-through-deep-pink text-uppercase text-deep-pink text-small margin-15px-top xs-margin-10px-top display-inline-block"
                    >
                      call us
                    </a>
                  </div>
                  {/* end contact info item */}
                  {/* start contact info item */}
                  <div
                    className="col-md-3 col-sm-6 col-xs-12 text-center xs-margin-30px-bottom wow fadeInUp last-paragraph-no-margin"
                    data-wow-delay="0.4s"
                  >
                    <div className="display-inline-block margin-20px-bottom">
                      <div className="bg-extra-dark-gray icon-round-medium">
                        <i className="icon-envelope icon-medium text-white" />
                      </div>
                    </div>
                    <div className="text-extra-dark-gray text-uppercase text-small font-weight-600 alt-font margin-5px-bottom">
                      E-mail Us
                    </div>
                    <p className="center-col">
                      <a href="mailto:info@yourdomain.com">info@yourdomain.com</a>
                      <br />
                      <a href="mailto:hr@yourdomain.com">hr@yourdomain.com</a>
                    </p>
                    <a
                      href="#"
                      className="text-decoration-line-through-deep-pink text-uppercase text-deep-pink text-small margin-15px-top xs-margin-10px-top display-inline-block"
                    >
                      send e-mail
                    </a>
                  </div>
                  {/* end contact info item */}
                  {/* start contact info item */}
                  <div
                    className="col-md-3 col-sm-6 col-xs-12 text-center wow fadeInUp last-paragraph-no-margin"
                    data-wow-delay="0.6s"
                  >
                    <div className="display-inline-block margin-20px-bottom">
                      <div className="bg-extra-dark-gray icon-round-medium">
                        <i className="icon-megaphone icon-medium text-white" />
                      </div>
                    </div>
                    <div className="text-extra-dark-gray text-uppercase text-small font-weight-600 alt-font margin-5px-bottom">
                      Customer Services
                    </div>
                    <p className="xs-width-100 center-col">
                      Lorem Ipsum is simply dummy
                      <br />
                      text of the printing.
                    </p>
                    <a
                      href="#"
                      className="text-decoration-line-through-deep-pink text-uppercase text-deep-pink text-small margin-15px-top xs-margin-10px-top display-inline-block"
                    >
                      open ticket
                    </a>
                  </div>
                  {/* end contact info item */}
                </div>
              </div>
            </div>
          </section>
          {/* end contact info section */}
          {/* start contact form */}
          <section className="wow fadeIn big-section" id="section-down">
            <div className="container">
              <div className="row equalize sm-equalize-auto">
                <div className="col-md-6 col-sm-12 col-xs-12 sm-margin-30px-bottom wow fadeInLeft">
                  <div className="padding-fifteen-all bg-light-gray border-radius-6 md-padding-seven-all xs-padding-30px-all height-100">
                    <span className="text-extra-dark-gray alt-font text-large font-weight-600 margin-25px-bottom display-block">
                      Contact Us
                    </span>
                    <form id="contact-form" action="javascript:void(0)" method="post">
                      <div>
                        <div id="success-contact-form" className="no-margin-lr" />
                        <input
                          type="text"
                          name="name"
                          id="name"
                          placeholder="Law School*"
                          className="border-radius-4 bg-white medium-input"
                        />
                        <input
                          type="text"
                          name="email"
                          id="email"
                          placeholder="Law School Batch *"
                          className="border-radius-4 bg-white medium-input"
                        />
                        <input
                          type="text"
                          name="subject"
                          id="subject"
                          placeholder="Any Previous Experience"
                          className="border-radius-4 bg-white medium-input"
                        />
                        <textarea
                          name="comment"
                          id="comment"
                          placeholder="Your Message"
                          rows={5}
                          className="border-radius-4 bg-white medium-textarea"
                          defaultValue={""}
                        />
                        <button
                          id="contact-us-button"
                          type="submit"
                          className="btn btn-small border-radius-4 btn-dark-gray"
                        >
                          send message
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="col-md-6 col-sm-12 col-xs-12 last-paragraph-no-margin wow fadeInRight">
                  <div className="padding-ten-all bg-light-gray border-radius-6 md-padding-seven-all xs-padding-30px-all height-100 sm-text-center">
                    <img
                      src="http://placehold.it/1000x700"
                      alt=""
                      className="border-radius-6 margin-35px-bottom xs-margin-30px-bottom"
                    />
                    <span className="text-large font-weight-600 alt-font text-extra-dark-gray margin-5px-bottom display-block">
                      Let's plan for a new project?
                    </span>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and typesetting
                      industry, Lorem Ipsum has been the standard dummy text.
                    </p>
                    <a
                      href="about-us-modern.html"
                      className="btn btn-dark-gray btn-small text-extra-small border-radius-4 margin-25px-top"
                    >
                      About Company
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* end contact form */}
          {/* start map section */}
          <section className="no-padding one-second-screen sm-height-400px wow fadeIn">
            <iframe
              className="width-100 height-100"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.843821917424!2d144.956054!3d-37.817127!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad65d4c2b349649%3A0xb6899234e561db11!2sEnvato!5e0!3m2!1sen!2sin!4v1427947693651"
            />
          </section>
          {/* end map section */}
          {/* start social section */}
          <section className="wow fadeIn">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center social-style-1 round social-icon-style-5">
                  <ul className="large-icon no-margin-bottom">
                    <li>
                      <a
                        className="facebook"
                        href="http://facebook.com"
                        target="_blank"
                      >
                        <i className="fab fa-facebook-f" />
                        <span />
                      </a>
                    </li>
                    <li>
                      <a className="twitter" href="http://twitter.com" target="_blank">
                        <i className="fab fa-twitter" />
                        <span />
                      </a>
                    </li>
                    <li>
                      <a className="google" href="http://google.com" target="_blank">
                        <i className="fab fa-google-plus-g" />
                        <span />
                      </a>
                    </li>
                    <li>
                      <a
                        className="dribbble"
                        href="http://dribbble.com"
                        target="_blank"
                      >
                        <i className="fab fa-dribbble" />
                        <span />
                      </a>
                    </li>
                    <li>
                      <a
                        className="linkedin"
                        href="http://linkedin.com"
                        target="_blank"
                      >
                        <i className="fab fa-linkedin-in" />
                        <span />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
        </>

        {/* start footer */}
        <footer className="footer-standard-dark bg-extra-dark-gray">
          <div className="footer-widget-area padding-five-tb xs-padding-30px-tb">
            <div className="container">
              <div className="row equalize xs-equalize-auto">
                <div className="col-md-3 col-sm-6 col-xs-12 widget border-right border-color-medium-dark-gray sm-no-border-right sm-margin-30px-bottom xs-text-center">
                  {/* start logo */}
                  <a
                    href="index.html"
                    className="margin-20px-bottom display-inline-block"
                  >
                    <img
                      className="footer-logo"
                      src="images/logo-white.png"
                      data-rjs="images/logo-white@2x.png"
                      alt=""
                    />
                  </a>
                  {/* end logo */}
                  <p className="text-small width-95 xs-width-100">
                    Lorem Ipsum is simply dummy text of the printing and typesetting
                    industry. Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </p>
                  {/* start social media */}
                  <div className="social-icon-style-8 display-inline-block vertical-align-middle">
                    <ul className="small-icon no-margin-bottom">
                      <li>
                        <a
                          className="facebook text-white"
                          href="https://www.facebook.com/"
                          target="_blank"
                        >
                          <i className="fab fa-facebook-f" aria-hidden="true" />
                        </a>
                      </li>
                      <li>
                        <a
                          className="twitter text-white"
                          href="https://twitter.com/"
                          target="_blank"
                        >
                          <i className="fab fa-twitter" />
                        </a>
                      </li>
                      <li>
                        <a
                          className="google text-white"
                          href="https://plus.google.com"
                          target="_blank"
                        >
                          <i className="fab fa-google-plus-g" />
                        </a>
                      </li>
                      <li>
                        <a
                          className="instagram text-white"
                          href="https://instagram.com/"
                          target="_blank"
                        >
                          <i
                            className="fab fa-instagram no-margin-right"
                            aria-hidden="true"
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                  {/* end social media */}
                </div>
                {/* start additional links */}
                <div className="col-md-3 col-sm-6 col-xs-12 widget border-right border-color-medium-dark-gray padding-45px-left sm-padding-15px-left sm-no-border-right sm-margin-30px-bottom xs-text-center">
                  <div className="widget-title alt-font text-small text-medium-gray text-uppercase margin-10px-bottom font-weight-600">
                    Additional Links
                  </div>
                  <ul className="list-unstyled">
                    <li>
                      <a className="text-small" href="index.html">
                        Home Classic Corporate
                      </a>
                    </li>
                    <li>
                      <a className="text-small" href="home-creative-business.html">
                        Home Creative Business
                      </a>
                    </li>
                    <li>
                      <a className="text-small" href="home-creative-designer.html">
                        Home Creative Designer
                      </a>
                    </li>
                    <li>
                      <a className="text-small" href="home-portfolio-minimal.html">
                        Home Portfolio Minimal
                      </a>
                    </li>
                    <li>
                      <a className="text-small" href="home-portfolio-parallax.html">
                        Home Portfolio parallax
                      </a>
                    </li>
                    <li>
                      <a className="text-small" href="home-portfolio-personal.html">
                        Home Portfolio Personal
                      </a>
                    </li>
                  </ul>
                </div>
                {/* end additional links */}
                {/* start contact information */}
                <div className="col-md-3 col-sm-6 col-xs-12 widget border-right border-color-medium-dark-gray padding-45px-left sm-padding-15px-left sm-clear-both sm-no-border-right  xs-margin-30px-bottom xs-text-center">
                  <div className="widget-title alt-font text-small text-medium-gray text-uppercase margin-10px-bottom font-weight-600">
                    Contact Info
                  </div>
                  <p className="text-small display-block margin-15px-bottom width-80 xs-center-col">
                    POFO Design Agency
                    <br /> 301 The Greenhouse, Custard Factory, London, E2 8DY.
                  </p>
                  <div className="text-small">
                    Email: <a href="mailto:sales@domain.com">sales@domain.com</a>
                  </div>
                  <div className="text-small">Phone: +44 (0) 123 456 7890</div>
                  <a
                    href="contact-us-modern.html"
                    className="text-small text-uppercase text-decoration-underline"
                  >
                    View Direction
                  </a>
                </div>
                {/* end contact information */}
                {/* start instagram */}
                <div className="col-md-3 col-sm-6 col-xs-12 widget padding-45px-left sm-padding-15px-left xs-text-center">
                  <div className="widget-title alt-font text-small text-medium-gray text-uppercase margin-20px-bottom font-weight-600">
                    Instagram portfolio
                  </div>
                  <div className="instagram-follow-api">
                    <ul id="instaFeed-footer" />
                  </div>
                </div>
                {/* end instagram */}
              </div>
            </div>
          </div>
          <div className="bg-dark-footer padding-50px-tb text-center xs-padding-30px-tb">
            <div className="container">
              <div className="row">
                {/* start copyright */}
                <div className="col-md-6 col-sm-6 col-xs-12 text-left text-small xs-text-center">
                  © 2017 POFO is Proudly Powered by{" "}
                  <a
                    href="http://www.themezaa.com"
                    target="_blank"
                    className="text-dark-gray"
                  >
                    ThemeZaa
                  </a>
                </div>
                <div className="col-md-6 col-sm-6 col-xs-12 text-right text-small xs-text-center">
                  <a href="javascript:void(0);" className="text-dark-gray">
                    Term and Condition
                  </a>
                  &nbsp;&nbsp;|&nbsp;&nbsp;
                  <a href="javascript:void(0);" className="text-dark-gray">
                    Privacy Policy
                  </a>
                </div>
                {/* end copyright */}
              </div>
            </div>
          </div>
        </footer>
      </>

    </div>
  )
}

export default Contact
