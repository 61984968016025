import React from 'react'
import Header from './Header'
import Navbar from './Navbar'

const Faq = () => {
    return (
        <div>
            <>
                {/* <Header /> */}
               <Navbar/>
                <section
                    className="wow fadeIn cover-background background-position-top top-space"
                    style={{ backgroundImage: 'url("http://placehold.it/1920x1080")' }}
                >
                    <div className="opacity-medium bg-extra-dark-gray" />
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-xs-12 display-table page-title-large">
                                <div className="display-table-cell vertical-align-middle text-center padding-30px-tb">
                                    {/* start sub title */}
                                    <span className="display-block text-white opacity6 width-45 sm-width-100 center-col alt-font margin-10px-bottom">
                                        Frequently asked questions
                                    </span>
                                    {/* end sub title */}
                                    {/* start page title */}
                                    <h1 className="alt-font text-white font-weight-600 no-margin-bottom">
                                        FAQs
                                    </h1>
                                    {/* end page title */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* end page title section */}
                {/* start breadcrumb section */}
                <section className="wow fadeIn padding-20px-tb border-bottom border-color-extra-light-gray">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 display-table">
                                <div className="display-table-cell vertical-align-middle text-left">
                                    <div className="breadcrumb alt-font text-small no-margin-bottom">
                                        {/* breadcrumb */}
                                        <ul>
                                            <li>
                                                <a href="#" className="text-medium-gray">
                                                    Home
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#" className="text-medium-gray">
                                                    Pages
                                                </a>
                                            </li>
                                            <li className="text-medium-gray">FAQs</li>
                                        </ul>
                                        {/* end breadcrumb */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* end breadcrumb section */}
                {/* start answer section */}
                <section className="wow fadeIn">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h6 className="text-uppercase alt-font text-extra-dark-gray font-weight-600 margin-four-bottom">
                                    Regular &amp; Extended Licenses
                                </h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-sm-12 center-col">
                                {/* start accordion */}
                                <div className="panel-group accordion-style1" id="accordion-one">
                                    {/* start accordion item */}
                                    <div className="panel">
                                        <div className="panel-heading">
                                            <a
                                                data-toggle="collapse"
                                                data-parent="#accordion-one"
                                                href="#accordion-one-link1"
                                                className="collapsed"
                                                aria-expanded="false"
                                            >
                                                <div className="panel-title font-weight-500 text-uppercase position-relative padding-20px-right">
                                                What additional services or guidance do you offer to law students?
                                                    <span className="pull-right position-absolute right-0 top-0">
                                                        <i className="ti-plus" />
                                                    </span>
                                                </div>
                                            </a>
                                        </div>
                                        <div id="accordion-one-link1" className="panel-collapse collapse">
                                            <div className="panel-body">
                                                <p>
                                                Yes, our agency aids in transitioning careers for experienced professionals who are exploring new horizons, or shifting from one practice area to another.
                                                </p>
                                               
                                            </div>
                                        </div>
                                    </div>
                                    {/* end accordion item */}
                                    {/* start accordion item */}
                                    <div className="panel">
                                        <div className="panel-heading">
                                            <a
                                                data-toggle="collapse"
                                                data-parent="#accordion-one"
                                                href="#accordion-one-link2"
                                                className="collapsed"
                                                aria-expanded="false"
                                            >
                                                <div className="panel-title font-weight-500 text-uppercase position-relative padding-20px-right">
                                                  
Why you should choose us?
                                                    <span className="pull-right position-absolute right-0 top-0">
                                                        <i className="ti-plus" />
                                                    </span>
                                                </div>
                                            </a>
                                        </div>
                                        <div id="accordion-one-link2" className="panel-collapse collapse">
                                            <div className="panel-body">
                                                <p>
                                                Personalised approach: Through our tailored matchmaking process, we ensure that our placements align with the culture and needs of both clients and candidates
 Expertise and insight: Through our seasoned professional staff and deep industry knowledge and legal insights we offer our clients valuable market intelligence.
 Success Stories: Our success stories stand as a testament for our clients and potential candidates that demonstrate our agency’s effectiveness and positive impact on legal careers.

                                                </p>
                                               
                                            </div>
                                        </div>
                                    </div>
                                    
                                    {/* end accordion item */}
                                </div>
                                {/* end accordion */}
                            </div>
                        </div>
                    </div>
                </section>
                {/* end answer section */}
                {/* start answer section */}
               
                {/* end answer section */}
                {/* start footer */}
                <footer className="footer-standard-dark bg-extra-dark-gray">
                    <div className="footer-widget-area padding-five-tb xs-padding-30px-tb">
                        <div className="container">
                            <div className="row equalize xs-equalize-auto">
                                <div className="col-md-3 col-sm-6 col-xs-12 widget border-right border-color-medium-dark-gray sm-no-border-right sm-margin-30px-bottom xs-text-center">
                                    {/* start logo */}
                                    <a
                                        href="index.html"
                                        className="margin-20px-bottom display-inline-block"
                                    >
                                        <img
                                            className="footer-logo"
                                            src="images/logo-white.png"
                                            data-rjs="images/logo-white@2x.png"
                                            alt="Pofo"
                                        />
                                    </a>
                                    {/* end logo */}
                                    <p className="text-small width-95 xs-width-100">
                                        Lorem Ipsum is simply dummy text of the printing and typesetting
                                        industry. Lorem Ipsum is simply dummy text of the printing and
                                        typesetting industry.
                                    </p>
                                    {/* start social media */}
                                    <div className="social-icon-style-8 display-inline-block vertical-align-middle">
                                        <ul className="small-icon no-margin-bottom">
                                            <li>
                                                <a
                                                    className="facebook text-white"
                                                    href="https://www.facebook.com/"
                                                    target="_blank"
                                                >
                                                    <i className="fab fa-facebook-f" aria-hidden="true" />
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    className="twitter text-white"
                                                    href="https://twitter.com/"
                                                    target="_blank"
                                                >
                                                    <i className="fab fa-twitter" />
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    className="google text-white"
                                                    href="https://plus.google.com"
                                                    target="_blank"
                                                >
                                                    <i className="fab fa-google-plus-g" />
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    className="instagram text-white"
                                                    href="https://instagram.com/"
                                                    target="_blank"
                                                >
                                                    <i
                                                        className="fab fa-instagram no-margin-right"
                                                        aria-hidden="true"
                                                    />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    {/* end social media */}
                                </div>
                                {/* start additional links */}
                                <div className="col-md-3 col-sm-6 col-xs-12 widget border-right border-color-medium-dark-gray padding-45px-left sm-padding-15px-left sm-no-border-right sm-margin-30px-bottom xs-text-center">
                                    <div className="widget-title alt-font text-small text-medium-gray text-uppercase margin-10px-bottom font-weight-600">
                                        Additional Links
                                    </div>
                                    <ul className="list-unstyled">
                                        <li>
                                            <a className="text-small" href="index.html">
                                                Home Classic Corporate
                                            </a>
                                        </li>
                                        <li>
                                            <a className="text-small" href="home-creative-business.html">
                                                Home Creative Business
                                            </a>
                                        </li>
                                        <li>
                                            <a className="text-small" href="home-creative-designer.html">
                                                Home Creative Designer
                                            </a>
                                        </li>
                                        <li>
                                            <a className="text-small" href="home-portfolio-minimal.html">
                                                Home Portfolio Minimal
                                            </a>
                                        </li>
                                        <li>
                                            <a className="text-small" href="home-portfolio-parallax.html">
                                                Home Portfolio parallax
                                            </a>
                                        </li>
                                        <li>
                                            <a className="text-small" href="home-portfolio-personal.html">
                                                Home Portfolio Personal
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                {/* end additional links */}
                                {/* start contact information */}
                                <div className="col-md-3 col-sm-6 col-xs-12 widget border-right border-color-medium-dark-gray padding-45px-left sm-padding-15px-left sm-clear-both sm-no-border-right  xs-margin-30px-bottom xs-text-center">
                                    <div className="widget-title alt-font text-small text-medium-gray text-uppercase margin-10px-bottom font-weight-600">
                                        Contact Info
                                    </div>
                                    <p className="text-small display-block margin-15px-bottom width-80 xs-center-col">
                                        POFO Design Agency
                                        <br /> 301 The Greenhouse, Custard Factory, London, E2 8DY.
                                    </p>
                                    <div className="text-small">
                                        Email: <a href="mailto:sales@domain.com">sales@domain.com</a>
                                    </div>
                                    <div className="text-small">Phone: +44 (0) 123 456 7890</div>
                                    <a
                                        href="contact-us-modern.html"
                                        className="text-small text-uppercase text-decoration-underline"
                                    >
                                        View Direction
                                    </a>
                                </div>
                                {/* end contact information */}
                                {/* start instagram */}
                                <div className="col-md-3 col-sm-6 col-xs-12 widget padding-45px-left sm-padding-15px-left xs-text-center">
                                    <div className="widget-title alt-font text-small text-medium-gray text-uppercase margin-20px-bottom font-weight-600">
                                        Instagram portfolio
                                    </div>
                                    <div className="instagram-follow-api">
                                        <ul id="instaFeed-footer" />
                                    </div>
                                </div>
                                {/* end instagram */}
                            </div>
                        </div>
                    </div>
                    <div className="bg-dark-footer padding-50px-tb text-center xs-padding-30px-tb">
                        <div className="container">
                            <div className="row">
                                {/* start copyright */}
                                <div className="col-md-6 col-sm-6 col-xs-12 text-left text-small xs-text-center">
                                    © 2017 POFO is Proudly Powered by{" "}
                                    <a
                                        href="http://www.themezaa.com"
                                        target="_blank"
                                        className="text-dark-gray"
                                    >
                                        ThemeZaa
                                    </a>
                                </div>
                                <div className="col-md-6 col-sm-6 col-xs-12 text-right text-small xs-text-center">
                                    <a href="javascript:void(0);" className="text-dark-gray">
                                        Term and Condition
                                    </a>
                                    &nbsp;&nbsp;|&nbsp;&nbsp;
                                    <a href="javascript:void(0);" className="text-dark-gray">
                                        Privacy Policy
                                    </a>
                                </div>
                                {/* end copyright */}
                            </div>
                        </div>
                    </div>
                </footer>
            </>

        </div>
    )
}

export default Faq
