import React from 'react'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { NavLink } from 'react-router-dom';
const SliderSection = () => {
  const CustomPrevArrow = ({ onClick }) => (
    <div className="prev-arrow" onClick={onClick}>
      <FontAwesomeIcon icon={faChevronLeft} />
    </div>
  );

  const CustomNextArrow = ({ onClick }) => (
    <div className="next-arrow" onClick={onClick}>
      <FontAwesomeIcon icon={faChevronRight} />
    </div>
  );
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000
    // prevArrow: <CustomPrevArrow />,
    // nextArrow: <CustomNextArrow />,
};

  const style = {
    zIndex: 8,
    whiteSpace: 'nowrap',
    fontWeight: 600,
    letterSpacing: '-1px',
    fontFamily: 'Montserrat, sans-serif',
    visibility: 'inherit',
    transition: 'none 0s ease 0s',
    textAlign: 'center',
    lineHeight: '75px',
    margin: '0px',
    padding: '10px 30px',
    fontSize: '70px',
    minHeight: '0px',
    minWidth: '0px',
    maxHeight: 'none',
    maxWidth: 'none',
    color: 'rgb(255, 255, 255)',
    opacity: 1,
    transform: 'matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)',
    transformOrigin: '50% 50% 0px',
    boxShadow: 'rgb(153, 153, 153) 0px 0px 0px 0px',
    borderRadius: '0px'
  };

  const style1 = {
    zIndex: 7,
    whiteSpace: 'nowrap',
    textTransform: 'uppercase',
    fontWeight: 500,
    letterSpacing: '2px',
    fontFamily: 'Montserrat, sans-serif',
    visibility: 'inherit',
    transition: 'none 0s ease 0s',
    textAlign: 'inherit',
    lineHeight: '20px',
    margin: '0px',
    padding: '10px 30px',
    fontSize: '12px',
    minHeight: '0px',
    minWidth: '0px',
    maxHeight: 'none',
    maxWidth: 'none',
    color: 'rgb(255, 255, 255)',
    opacity: 1,
    transform: 'matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)',
    transformOrigin: '50% 50% 0px',
    boxShadow: 'rgb(153, 153, 153) 0px 0px 0px 0px',
    borderRadius: '0px'
  };


  return (
    <section
      className="wow animate__fadeIn example no-padding no-transition"
      style={{ visibility: "visible", animationName: "fadeIn" }}
    >
      <article className="content">
        <div
          className="forcefullwidth_wrapper_tp_banner"
          id="rev_slider_1078_1_forcefullwidth"
          style={{
            position: "relative",
            width: "100%",
            height: "auto",
            marginTop: 0,
            marginBottom: 0
          }}
        >
          <div
            id="rev_slider_1078_1_wrapper"
            className="rev_slider_wrapper fullwidthbanner-container"
            data-alias="classic4export"
            data-source="gallery"
            style={{
              margin: "0px auto",
              backgroundColor: "transparent",
              padding: 0,
              position: "absolute",
              maxHeight: "none",
              overflow: "visible",
              height: 730,
              width: 1519,
              left: 0
            }}
          >
            {/* start revolution slider 5.4.1 fullwidth mode */}
            <div
              id="rev_slider_1078_1"
              className="rev_slider fullwidthabanner revslider-initialised tp-simpleresponsive rev_redraw_on_blurfocus"
              style={{
                maxHeight: "none",
                marginTop: 0,
                marginBottom: 0,
                height: "100%"
              }}
              data-version="5.4.1"
              data-slideactive="rs-2"
            >
              <ul
                className="tp-revslider-mainul"
                style={{
                  visibility: "visible",
                  display: "block",
                  overflow: "hidden",
                  width: "100%",
                  height: "100%",
                  maxHeight: "none"
                }}
              >
                {/* <Slider {...settings}> */}
                <li
                  data-index="rs-2"
                  data-transition="fade"
                  data-slotamount={3}
                  data-hideafterloop={0}
                  data-hideslideonmobile="off"
                  data-easein="default"
                  data-easeout="default"
                  data-masterspeed={500}
                  data-thumb="https://www.themezaa.com/html/pofo/images/homepage-option17-slider-01.jpg"
                  data-rotate={0}
                  data-saveperformance="off"
                  data-title=""
                  data-param1=""
                  data-param2=""
                  data-param3=""
                  data-param4=""
                  data-param5=""
                  data-param6=""
                  data-param7=""
                  data-param8=""
                  data-param9=""
                  data-param10=""
                  data-description=""
                  className="tp-revslider-slidesli active-revslide"
                  style={{
                    perspective: 6000,
                    width: "100%",
                    height: "100%",
                    overflow: "hidden",
                    zIndex: 20,
                    visibility: "inherit",
                    opacity: 1,
                    backgroundColor: "rgba(255, 255, 255, 0)"
                  }}
                >
                  <div className="opacity-extra-medium bg-black position-relative z-index-1" />
                  {/* main image */}
                  <div
                    className="slotholder"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      zIndex: 0,
                      width: "100%",
                      height: "100%",
                      backfaceVisibility: "hidden",
                      transform: "translate3d(0px, 0px, 0px)",
                      visibility: "inherit",
                      opacity: 1
                    }}
                  >
                    {/*Runtime Modification - Img tag is Still Available for SEO Goals in Source - <img src="images/homepage-3-slider-img-1.jpg" alt="" data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat" data-bgparallax="1" class="rev-slidebg defaultimg" data-no-retina="">*/}
                    <div
                      className="tp-bgimg defaultimg "
                      data-bgcolor="undefined"
                      style={{
                        backgroundRepeat: "no-repeat",
                        backgroundImage:
                          'url("https://www.themezaa.com/html/pofo/images/homepage-option17-slider-01.jpg")',
                        backgroundSize: "cover",
                        backgroundPosition: "center center",
                        width: "100%",
                        height: "100%",
                        opacity: 1,
                        visibility: "inherit",
                        zIndex: 20
                      }}
                      src="https://www.themezaa.com/html/pofo/images/homepage-option17-slider-01.jpg"
                    />
                  </div>
                  {/* layer nr. 3 */}
                  <div
                    className="tp-parallax-wrap"
                    style={{
                      position: "absolute",
                      display: "block",
                      visibility: "visible",
                      left: 599,
                      top: 230,
                      zIndex: 7
                    }}
                  >
                    <div
                      className="tp-loop-wrap"
                      style={{ position: "absolute", display: "block" }}
                    >
                      <div
                        className="tp-mask-wrap"
                        style={{
                          position: "absolute",
                          display: "block",
                          overflow: "visible",
                          transform: "matrix(1, 0, 0, 1, 0, 0)"
                        }}
                      >
                        <div
                          className="tp-caption NotGeneric-Title tp-resizeme rs-hover-ready"
                          data-x="['center','center','center','center']"
                          data-hoffset="['0','0','0','0']"
                          data-y="['middle','middle','middle','middle']"
                          data-voffset="['-115','-110','-90','-90']"
                          data-fontsize="['12','12','12','12']"
                          data-lineheight="['20','20','20','20']"
                          data-width="none"
                          data-height="none"
                          data-whitespace="nowrap"
                          data-visibility="['on', 'on', 'on', 'on']"
                          data-type="text"
                          data-color="['#FFF']"
                          data-responsive_offset="on"
                          data-responsive="on"
                          data-frames='[{"from":"y:50px;opacity:0;","speed":1000,"to":"o:1;","delay":300,"ease":"Power4.easeInOut"},{"delay":"wait","speed":1000,"to":"y:[175%];","mask":"x:inherit;y:inherit;s:inherit;e:inherit;","ease":"Power2.easeInOut"},{"frame":"hover","speed":"300","ease":"Power1.easeInOut","to":"o:1;rX:0;rY:0;rZ:0;z:0;","style":"c:rgba(255, 255, 255, 1.00);bc:rgba(255, 255, 255, 1.00);bw:2px 2px 2px 2px;"}]'
                          data-textalign="['inherit', 'inherit', 'right', 'center']"
                          data-paddingtop="[10,10,10,10]"
                          data-paddingright="[30,30,30,30]"
                          data-paddingbottom="[10,10,10,10]"
                          data-paddingleft="[30,30,30,30]"
                          data-basealign="slide"
                          style={style1}
                          id="layer-900149169"
                        >
                          we work hard, we play hard
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* layer nr. 4 */}
                  <div
                    className="tp-parallax-wrap"
                    style={{
                      position: "absolute",
                      display: "block",
                      visibility: "visible",
                      left: 472,
                      top: 280,
                      zIndex: 8
                    }}
                  >
                    <div
                      className="tp-loop-wrap"
                      style={{ position: "absolute", display: "block" }}
                    >
                      <div
                        className="tp-mask-wrap"
                        style={{
                          position: "absolute",
                          display: "block",
                          overflow: "visible",
                          transform: "matrix(1, 0, 0, 1, 0, 0)"
                        }}
                      >
                        <div
                          style={style}
                          // id="divid"
                          className="tp-caption NotGeneric-SubTitle tp-resizeme rs-hover-ready divid"
                          data-x="['center','center','center','center']"
                          data-hoffset="['0','0','0','0']"
                          data-y="['middle','middle','middle','middle']"
                          data-voffset="['0','0','0','0']"
                          data-fontsize="['70','70','60','50']"
                          data-lineheight="['75','75','60','60']"
                          data-width="none"
                          data-height="none"
                          data-whitespace="nowrap"
                          data-visibility="['on', 'on', 'on', 'on']"
                          data-type="button"
                          data-color="['#FFF']"
                          data-responsive_offset="on"
                          data-responsive="on"
                          data-frames='[{"from":"y:50px;opacity:0;","speed":1500,"to":"o:1;","delay":600,"ease":"Power4.easeInOut"},{"delay":"wait","speed":1000,"to":"y:[175%];","mask":"x:inherit;y:inherit;s:inherit;e:inherit;","ease":"Power2.easeInOut"},{"frame":"hover","speed":"300","ease":"Power1.easeInOut","to":"o:1;rX:0;rY:0;rZ:0;z:0;","style":"c:rgba(255, 255, 255, 1.00);bc:rgba(255, 255, 255, 1.00);bw:2px 2px 2px 2px;"}]'
                          data-textalign="['center', 'center', 'center', 'center']"
                          data-paddingtop="[10,10,10,10]"
                          data-paddingright="[30,30,30,30]"
                          data-paddingbottom="[10,10,10,10]"
                          data-paddingleft="[30,30,30,30]"
                          data-basealign="slide"
                        
                          id="layer-552697687"
                        >
                          We are a web
                          <br />
                          design agency
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* layer nr. 5 */}
                  <div
                    className="tp-parallax-wrap"
                    style={{
                      position: "absolute",
                      display: "block",
                      visibility: "visible",
                      left: 681,
                      top: 476,
                      zIndex: 1
                    }}
                  >
                    <div
                      className="tp-loop-wrap"
                      style={{ position: "absolute", display: "block" }}
                    >
                      <div
                        className="tp-mask-wrap"
                        style={{
                          position: "absolute",
                          display: "block",
                          overflow: "visible",
                          transform: "matrix(1, 0, 0, 1, 0, 0)"
                        }}
                      >
                        <NavLink
                          to="/"
                          className="tp-caption btn btn-transparent-white btn-medium border-radius-4 z-index-5 rs-hover-ready"
                          data-x="['center','center','center','center']"
                          data-hoffset="['0','0','0','0']"
                          data-y="['middle','middle','middle','middle']"
                          data-voffset="['130','130','110','110']"
                          data-fontsize="['12','14','14','14']"
                          data-whitespace="nowrap"
                          data-visibility="['on', 'on', 'on', 'on']"
                          data-type="text"
                          data-color="['#FFFFFF']"
                          data-responsive_offset="on"
                          data-responsive="on"
                          data-frames='[{"from":"y:150px;opacity:0;","speed":1500,"to":"o:1;","delay":900,"ease":"Power4.easeInOut"},{"delay":"wait","speed":1000,"to":"y:[175%];","mask":"x:inherit;y:inherit;s:inherit;e:inherit;","ease":"Power2.easeInOut"},{"frame":"hover","speed":"300","ease":"Power1.easeInOut","to":"o:1;rX:0;rY:0;rZ:0;z:0;","style":"c:rgba(0, 0, 0, 1.00);bc:rgba(255, 255, 255, 1.00);bw:2px 2px 2px 2px;"}]'
                          data-textalign="['inherit', 'inherit', 'right', 'center']"
                          data-paddingtop="[6,6,6,6]"
                          data-paddingright="[26,26,26,26]"
                          data-paddingbottom="[6,6,6,6]"
                          data-paddingleft="[26,26,26,26]"
                          data-basealign="slide"
                          id="layer-941777419"
                          style={{
                            visibility: "inherit",
                            transition: "none 0s ease 0s",
                            textAlign: "inherit",

                            borderWidth: 1,
                            margin: 0,
                            padding: "6px 26px",
                            letterSpacing: 0,
                            fontWeight: 600,
                            fontSize: 12,
                            whiteSpace: "nowrap",
                            minHeight: 0,
                            minWidth: 0,
                            maxHeight: "none",
                            maxWidth: "none",
                            color: "rgb(255, 255, 255)",
                            opacity: 1,
                            transform:
                              "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
                            transformOrigin: "50% 50% 0px"
                          }}
                        >
                          Explore Work
                        </NavLink>
                      </div>
                    </div>
                  </div>
                  {/* layer nr. 6 */}
                </li>
                {/* </Slider> */}
                
              </ul>
              <div className="tp-loader off" style={{ display: "none" }}>
                <div className="dot1" />
                <div className="dot2" />
                <div className="bounce1" />
                <div className="bounce2" />
                <div className="bounce3" />
              </div>
              <div
                className="tp-bannertimer"
                style={{
                  visibility: "visible",
                  width: "60.5556%",
                  transform: "translate3d(0px, 0px, 0px)"
                }}
              />
              <div
                className="tp-leftarrow tparrows zeus"
                style={{
                  top: "50%",
                  transform: "matrix(1, 0, 0, 1, 30, -35)",
                  left: 0,
                  visibility: "hidden",
                  opacity: 0
                }}
              >
                <div className="tp-title-wrap">
                  {" "}
                  <div
                    className="tp-arr-imgholder"
                    style={{
                      backgroundImage:
                        'url("images/classic-corporate-100x50-1.jpg")'
                    }}
                  />{" "}
                </div>
              </div>
              <div
                className="tp-rightarrow tparrows zeus"
                style={{
                  top: "50%",
                  transform: "matrix(1, 0, 0, 1, -100, -35)",
                  left: "100%",
                  visibility: "hidden",
                  opacity: 0
                }}
              >
                <div className="tp-title-wrap">
                  {" "}
                  <div
                    className="tp-arr-imgholder"
                    style={{
                      backgroundImage: 'url("images/classic-corporate-60x60-3.jpg")'
                    }}
                  />{" "}
                </div>
              </div>
              <div
                className="tp-bullets hermes horizontal nav-pos-hor-center nav-pos-ver-bottom nav-dir-horizontal"
                style={{
                  width: 46,
                  height: 10,
                  top: "100%",
                  transform: "matrix(1, 0, 0, 1, -23, -40)",
                  left: "50%"
                }}
              >
                <div className="tp-bullet" style={{ left: 0, top: 0 }}>
                  <span className="tp-bullet-img-wrap">
                    {" "}
                    <span
                      className="tp-bullet-image"
                      style={{
                        backgroundImage:
                          'url("images/classic-corporate-100x50-1.jpg")'
                      }}
                    />
                  </span>
                  <span className="tp-bullet-title" />
                </div>
                <div className="tp-bullet selected" style={{ left: 18, top: 0 }}>
                  <span className="tp-bullet-img-wrap">
                    {" "}
                    <span
                      className="tp-bullet-image"
                      style={{
                        backgroundImage:
                          'url("images/classic-corporate-60x60-2.jpg")'
                      }}
                    />
                  </span>
                  <span className="tp-bullet-title" />
                </div>
                <div className="tp-bullet" style={{ left: 36, top: 0 }}>
                  <span className="tp-bullet-img-wrap">
                    {" "}
                    <span
                      className="tp-bullet-image"
                      style={{
                        backgroundImage:
                          'url("images/classic-corporate-60x60-3.jpg")'
                      }}
                    />
                  </span>
                  <span className="tp-bullet-title" />
                </div>
              </div>
            </div>
          </div>
          <div
            className="tp-fullwidth-forcer"
            style={{ width: "100%", height: 730 }}
          />
        </div>
        {/* end revolution slider */}
      </article>
    </section>





  )
}

export default SliderSection
