import React from 'react'

const Login = () => {
    return (
        <section className="wow fadeIn bg-light-gray" style={{padding:'50px'}}>
            <div className="container">
              
                <form id="contact-form-3" action="javascript:void(0)" method="post">
                    <div className="row">
                        <div className="col-md-6 wow fadeIn center-col">
                            <div className="padding-fifteen-all bg-white border-radius-6 md-padding-seven-all">
                                <div className="text-extra-dark-gray alt-font text-large font-weight-600 margin-30px-bottom">
                                   Signup
                                </div>
                                <div>
                                    <div id="success-contact-form-3" className="no-margin-lr" />
                                    <input
                                        type="text"
                                        name="name"
                                        id="name"
                                        placeholder="Name*"
                                        className="input-bg"
                                    />
                                    <input
                                        type="text"
                                        name="email"
                                        id="email"
                                        placeholder="E-mail*"
                                        className="input-bg"
                                    />
                                    <input
                                        type="text"
                                        name="subject"
                                        id="subject"
                                        placeholder="Subject"
                                        className="input-bg"
                                    />
                                    <textarea
                                        name="comment"
                                        id="comment"
                                        placeholder="Your Message"
                                        className="input-bg"
                                        defaultValue={""}
                                    />
                                    <button
                                        id="contact-us-button-3"
                                        type="submit"
                                        className="btn btn-small border-radius-4 btn-black"
                                    >
                                        send message
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </section>


    )
}

export default Login
