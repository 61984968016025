import React, { useState } from 'react';
import Footer from './Footer'
import Navbar from './Navbar'
import { NavLink } from 'react-router-dom'
import { jsonData } from "../../src/data.js"
const JobSearch = () => {

  const [searchTerm, setSearchTerm] = useState('');
  const [filteredEmployees, setFilteredEmployees] = useState(jsonData.employees);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    filterEmployees(event.target.value);
  };

  const filterEmployees = (searchTerm) => {
    const filtered = jsonData.employees.filter((employee) =>
      employee.designation.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredEmployees(filtered);
  };
  return (
    <div>
      <Navbar />
      <section
        className="wow fadeIn parallax"
        data-stellar-background-ratio="0.5"
        style={{ backgroundImage: 'url("http://placehold.it/1920x1100")' }}
      >
        <div className="opacity-medium bg-extra-dark-gray" />
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12 extra-small-screen display-table page-title-large">
              <div className="display-table-cell vertical-align-middle text-center">
                {/* start page title */}
                <h1 className="text-white alt-font font-weight-600 letter-spacing-minus-1 margin-10px-bottom">
                  JOB SEARCH
                </h1>
                {/* end page title */}
                {/* start sub title */}
                <span className="text-white opacity6 alt-font">
                  Apply your resume today and get notifications regarding all job openings with your acquired PQE.
                </span>
                {/* end sub title */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="wow fadeIn" style={{ "padding": "0px 0px", "margin-top": "25px" }}>
        <div className="container">

          <div className="row">
            <div className="col-md-12 col-sm-12 center-col text-center btn-dual">
              {/* start buttons */}

              <div className="display-inline-block width-50 margin-45px-bottom xs-margin-25px-bottom">
                <form>
                  <div className="position-relative">
                    <input
                      type="text"
                      className="bg-transparent text-small no-margin border-color-extra-light-gray medium-input pull-left"
                      placeholder="Enter your keywords..."
                      value={searchTerm}
                      onChange={handleSearchChange}
                    />
                    <button
                      type="submit"
                      className="bg-transparent  btn position-absolute right-0 top-1"
                    >
                      <i className="fas fa-search no-margin-left" />
                    </button>
                  </div>
                </form>
              </div>

            </div>
          </div>
        </div>
      </div>


      <section className="wow fadeIn" style={{ "padding": "45px 0px" }} id="tab_sec1">

        {/* <section className="wow fadeIn"> */}
          <div className="container-fluid padding-five-lr">
         
            <div className="row equalize xs-equalize-auto">
            {
              filteredEmployees.map((elm)=>{
                return (
                  <div class="grid-item col-md-4 col-sm-6 col-xs-12 margin-30px-bottom xs-text-center wow fadeInUp">
                  <div class="blog-post bg-light-gray inner-match-height">
                      <div class="blog-post-images overflow-hidden position-relative">
                          <a href="blog-post-layout-01.html">
                              <img src="http://placehold.it/900x650" alt=""/>
                              {/* <div class="blog-hover-icon"><span class="text-extra-large font-weight-300">+</span></div> */}
                          </a>
                      </div>
                      <div class="post-details padding-40px-all sm-padding-20px-all">
                          <a href="blog-post-layout-01.html" class="alt-font post-title text-medium text-extra-dark-gray width-100 display-block md-width-100 margin-15px-bottom">{elm.designation}</a>
                          <p>{elm.location}</p>
                          <div class="separator-line-horrizontal-full bg-medium-gray margin-20px-tb"></div>
                          <div class="author">
                              <span class="text-medium-gray text-uppercase text-extra-small display-inline-block sm-display-block sm-margin-10px-top">by <a href="blog-masonry.html" class="text-medium-gray"></a>&nbsp;&nbsp;|&nbsp;&nbsp;13 May 2017</span>
                          </div>
                      </div>
                  </div>
              </div>
                )
              })
            }
           
            
            </div>
          </div>
        {/* </section> */}

      </section>



      <Footer />
    </div>
  )
}

export default JobSearch
