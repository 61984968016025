import { React, useEffect, useState, useRef } from "react";
import { NavLink } from 'react-router-dom'
import { scroller } from "react-scroll";
const Navbar = () => {
  const [isActive, setIsActive] = useState(false);

  const handleClick = () => {
    setIsActive(!isActive);
  };

  const scrollToSection = (sectionId) => {
    setTimeout(() => {
      scroller.scrollTo(sectionId, {
        duration: 1000,
        smooth: true,
        offset: -50, // Adjust this offset if needed
      });
    }, 100); // Delay in milliseconds
  };
  const [scrollPosition, setScrollPosition] = useState(0);
  console.log("Scroll position: " + scrollPosition);

  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY;
      setScrollPosition(position);
      console.log(scrollPosition);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrollPosition]);

  // useEffect(() => {
  //   console.log(scrollPosition);
  // }, [scrollPosition]);


  const scrollToSection1 = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      window.scrollTo({
        top: section.offsetTop,
        behavior: 'smooth'
      });
    }
  };

  useEffect(() => {
    const hash = window.location.hash;
    switch (hash) {
      case "#about-indic":
        scrollToSection("about-indic");
        break;
      case "#team":
        scrollToSection("team");
        break;
      case "#portfolio":
        scrollToSection("portfolio");
        break;
      case "#news":
        scrollToSection("news");
        break;
      default:
        break;
    }
  }, []); // Empty dependency array means this effect runs once after the component mounts
  return (
    <div>
      <header>
        {/* start navigation */}
        <nav className="navbar navbar-default bootsnav bg-transparent navbar-top header-dark white-link" style={{ backgroundColor: scrollPosition > 750 ? '#fafafa' : '' }}>
          <div className="container nav-header-container">
            <div className="row">
              {/* start logo */}
              <div className="col-md-2 col-xs-5">
                <NavLink to="/" title="Pofo" className="logo">
                  <img
                    src="images/logo.png"
                    data-rjs="images/logo@2x.png"
                    className="logo-dark"
                    alt="Pofo"
                  />
                  <img
                    src="images/logo-white.png"
                    data-rjs="images/logo-white@2x.png"
                    alt="Pofo"
                    className="logo-light default"
                  />
                </NavLink>
              </div>
              {/* end logo */}
              <div className="col-md-7 col-xs-2 width-auto pull-right accordion-menu xs-no-padding-right">
                <button
                  type="button"
                  className="navbar-toggle collapsed pull-right"
                  data-toggle="collapse"
                  data-target="#navbar-collapse-toggle-1"
                >
                  <span className="sr-only">toggle navigation</span>
                  <span className="icon-bar" />
                  <span className="icon-bar" />
                  <span className="icon-bar" />
                </button>
                <div
                  className="navbar-collapse collapse pull-right"
                  id="navbar-collapse-toggle-1"
                >
                  <ul
                    id="accordion"
                    className="nav navbar-nav navbar-left no-margin alt-font text-normal"
                    data-in="fadeIn"
                    data-out="fadeOut"

                  >
                    {/* start menu item */}
                    {/* <li className="dropdown megamenu-fw">
                      <NavLink to="/">Home</NavLink>
                    

                    </li>
                    <li className="dropdown megamenu-fw">
                      <NavLink  to="#about"
                        onClick={() => scrollToSection("about")}>   About Us</NavLink>
                

                    </li>
                    <li className="dropdown megamenu-fw">
                      <NavLink to="#founder" onClick={() => scrollToSection("founder")}>   Our Founder</NavLink>
                

                    </li>
                    <li className="dropdown megamenu-fw">
                      <NavLink   to="#forlaw"
                        onClick={() => scrollToSection("forlaw")}>  For Law Students</NavLink>
                    </li>
                    <li className="dropdown megamenu-fw">
                      <NavLink   to="/faq"
                        > FaQ</NavLink>
                    </li>
                    <li className="dropdown megamenu-fw">
                      <NavLink   to="/contact"
                        > CONTACT</NavLink>
                    </li> */}

                    <li className="dropdown megamenu-fw">
                      <NavLink to="/" style={{ color: scrollPosition > 750 ? 'black' : 'white' }}>Home</NavLink>
                    </li>
                    <li className="dropdown megamenu-fw" >
                      <NavLink to="/" onClick={() => scrollToSection("about")} style={{ color: scrollPosition > 750 ? 'black' : 'white' }}>About Us</NavLink>
                    </li>
                    <li className="dropdown megamenu-fw" style={{ color: `${scrollPosition}` > 750 ? 'black' : 'white' }}>
                      <NavLink to="/" onClick={() => scrollToSection("founder")} style={{ color: scrollPosition > 750 ? 'black' : 'white' }}>Our Founder</NavLink>
                    </li>
                    <li className="dropdown megamenu-fw">
                      <NavLink to="/" onClick={() => scrollToSection("forlaw")} style={{ color: scrollPosition > 750 ? 'black' : 'white' }}>For Law Students</NavLink>
                    </li>
                    <li className="dropdown megamenu-fw" >
                      <NavLink to="/" style={{ color: scrollPosition > 750 ? 'black' : 'white' }}>FaQ</NavLink>
                    </li>
                    <li className="dropdown megamenu-fw" >
                      <NavLink to="/" style={{ color: scrollPosition > 750 ? 'black' : 'white' }}>CONTACT</NavLink>
                    </li>






                  </ul>
                </div>
              </div>
              <div className="col-md-2 col-xs-5 width-auto">
                <div className="header-searchbar">

                  {/* start search input */}
                  {/* <form
                    id="search-header"
                    method="post"
                    action="search-result.html"
                    name="search-header"
                    className="mfp-hide search-form-result"
                  >
                    <div className="search-form position-relative">
                      <button
                        type="submit"
                        className="fas fa-search close-search search-button"
                      />
                      <input
                        type="text"
                        name="search"
                        className="search-input"
                        placeholder="Enter your keywords..."
                        autoComplete="off"
                      />
                    </div>
                  </form> */}
                  {/* end search input */}
                </div>
                <div className="header-social-icon xs-display-none">
                  <a
                    href="https://www.facebook.com/"
                    title="Facebook"
                    target="_blank"
                  >
                    <i className="fab fa-facebook-f" aria-hidden="true" />
                  </a>
                  <a href="https://twitter.com/" title="Twitter" target="_blank">
                    <i className="fab fa-twitter" />
                  </a>
                  <a href="https://dribbble.com/" title="Dribbble" target="_blank">
                    <i className="fab fa-dribbble" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </nav>
        {/* end navigation */}
      </header>
    </div>
  )
}

export default Navbar
