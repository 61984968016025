export const jsonData = {
  "employees": [
    {
      "id": 1,
      "name": "John Doe",
      "designation": "Software Engineer",
      "location": "San Francisco, CA"
    },
    {
      "id": 2,
      "name": "Jane Smith",
      "designation": "Data Scientist",
      "location": "New York, NY"
    },
    {
      "id": 3,
      "name": "Michael Johnson",
      "designation": "Project Manager",
      "location": "Chicago, IL"
    },
    {
      "id": 4,
      "name": "Emily Brown",
      "designation": "Marketing Specialist",
      "location": "Los Angeles, CA"
    },
    {
      "id": 5,
      "name": "David Lee",
      "designation": "Software Engineer",
      "location": "Seattle, WA"
    },
    {
      "id": 6,
      "name": "Sarah Adams",
      "designation": "Data Analyst",
      "location": "Boston, MA"
    },
    {
      "id": 7,
      "name": "Robert Johnson",
      "designation": "Product Manager",
      "location": "Austin, TX"
    },
    {
      "id": 8,
      "name": "Jessica Miller",
      "designation": "UX Designer",
      "location": "Denver, CO"
    },
    {
      "id": 9,
      "name": "Michael Smith",
      "designation": "Software Engineer",
      "location": "Portland, OR"
    },
    {
      "id": 10,
      "name": "Emily Wilson",
      "designation": "Marketing Manager",
      "location": "Miami, FL"
    },
    // Add more records here if needed
  ]
};
