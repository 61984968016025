import React from 'react'

const SecondService = () => {
  return (
    <section className="wow fadeIn">
    <div className="container">
      <div className="row text-center">
        <div className="col-md-12">
          <div className="alt-font margin-5px-bottom text-uppercase text-small text-medium-gray">
            What We Do
          </div>
          <h5 className="text-uppercase alt-font text-extra-dark-gray margin-20px-bottom font-weight-700 sm-width-100 xs-width-100">
            our services
          </h5>
          <span className="separator-line-horrizontal-medium-light2 bg-deep-pink display-table margin-auto width-100px" />
        </div>
        <div className="col-md-12 margin-100px-top sm-margin-70px-top xs-margin-50px-top no-padding-lr">
          {/* start services item */}
          <div className="col-md-4 col-sm-4 col-xs-12 team-block text-center feature-box-15 xs-margin-40px-bottom last-paragraph-no-margin wow fadeInUp">
            <figure>
              <div className="feature-box-content xs-width-100">
                <div className="feature-box-image">
                  <img src="http://placehold.it/900x650" alt="" />
                </div>
                <div className="hover-content bg-deep-pink">
                  <div className="display-table height-100 width-100">
                    <div className="vertical-align-middle display-table-cell padding-twelve-lr">
                      <span className="text-white display-inline-block text-center">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <figcaption>
                <div className="text-center margin-25px-top">
                  <div className="text-extra-dark-gray alt-font font-weight-600">
                    Design and Development
                  </div>
                  <p>600+ We created web design</p>
                </div>
              </figcaption>
            </figure>
          </div>
          {/* end services item */}
          {/* start services item */}
          <div
            className="col-md-4 col-sm-4 col-xs-12 team-block text-center feature-box-15 xs-margin-40px-bottom last-paragraph-no-margin wow fadeInUp"
            data-wow-delay="0.2s"
          >
            <figure>
              <div className="feature-box-content xs-width-100">
                <div className="feature-box-image">
                  <img src="http://placehold.it/900x650" alt="" />
                </div>
                <div className="hover-content bg-deep-pink">
                  <div className="display-table height-100 width-100">
                    <div className="vertical-align-middle display-table-cell padding-twelve-lr">
                      <span className="text-white display-inline-block text-center">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <figcaption>
                <div className="text-center margin-25px-top">
                  <div className="text-extra-dark-gray alt-font font-weight-600">
                    Social Media Marketing
                  </div>
                  <p>475+ We completed marketing</p>
                </div>
              </figcaption>
            </figure>
          </div>
          {/* end services item */}
          {/* start services item */}
          <div
            className="col-md-4 col-sm-4 col-xs-12 team-block text-center feature-box-15 last-paragraph-no-margin wow fadeInUp"
            data-wow-delay="0.6s"
          >
            <figure>
              <div className="feature-box-content xs-width-100">
                <div className="feature-box-image">
                  <img src="http://placehold.it/900x650" alt="" />
                </div>
                <div className="hover-content bg-deep-pink">
                  <div className="display-table height-100 width-100">
                    <div className="vertical-align-middle display-table-cell padding-twelve-lr">
                      <span className="text-white display-inline-block text-center">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <figcaption>
                <div className="text-center margin-25px-top">
                  <div className="text-extra-dark-gray alt-font font-weight-600">
                    Mobile App Development
                  </div>
                  <p>475+ We created mobile app</p>
                </div>
              </figcaption>
            </figure>
          </div>
          {/* end services item */}
        </div>
      </div>
    </div>
  </section>
  
  )
}

export default SecondService
