import { React, useEffect, useState, useRef } from "react";
import { NavLink, Link } from "react-router-dom";
import { scroller } from "react-scroll";
import SliderSection from "./SliderSection";
import StartSection from "./StartSection";
import Portfolio from "./Portfolio";
import Information from "./Information";
import Parallax from "./Parallax";
import SecondService from "./SecondService";
import SecondParallax from "./SecondParallax";
import Advantage from "./Advantage";
import Counter from "./Counter";
import Video from "./Video";
import ClientsSlider from "./ClientsSlider";
import TestimonialSlider from "./TestimonialSlider";
import LatestBlog from "./LatestBlog";
import Footer from "./Footer";
const Header = () => {
  const [isActive, setIsActive] = useState(false);

  const handleClick = () => {
    setIsActive(!isActive);
  };

  const scrollToSection = (sectionId) => {
    setTimeout(() => {
      scroller.scrollTo(sectionId, {
        duration: 1000,
        smooth: true,
        offset: -50, // Adjust this offset if needed
      });
    }, 100); // Delay in milliseconds
  };
  const [scrollPosition, setScrollPosition] = useState(0);
  console.log("Scroll position: " + scrollPosition);

  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY;
      setScrollPosition(position);
      console.log(scrollPosition);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrollPosition]);

  // useEffect(() => {
  //   console.log(scrollPosition);
  // }, [scrollPosition]);


  const scrollToSection1 = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      window.scrollTo({
        top: section.offsetTop,
        behavior: 'smooth'
      });
    }
  };

  useEffect(() => {
    const hash = window.location.hash;
    switch (hash) {
      case "#about-indic":
        scrollToSection("about-indic");
        break;
      case "#team":
        scrollToSection("team");
        break;
      case "#portfolio":
        scrollToSection("portfolio");
        break;
      case "#news":
        scrollToSection("news");
        break;
      default:
        break;
    }
  }, []); // Empty dependency array means this effect runs once after the component mounts
  return (
    <>
      <header>
        {/* start navigation */}
        <nav className="navbar navbar-default bootsnav bg-transparent navbar-top header-dark white-link" style={{ backgroundColor: scrollPosition > 750 ? '#fafafa' : '' }}>
          <div className="container nav-header-container">
            <div className="row">
              {/* start logo */}
              <div className="col-md-2 col-xs-5">
                <NavLink to="/" title="Pofo" className="logo">
                  <img
                    src="images/logo.png"
                    data-rjs="images/logo@2x.png"
                    className="logo-dark"
                    alt="Pofo"
                  />
                  <img
                    src="images/logo-white.png"
                    data-rjs="images/logo-white@2x.png"
                    alt="Pofo"
                    className="logo-light default"
                  />
                </NavLink>
              </div>
              {/* end logo */}
              <div className="col-md-7 col-xs-2 width-auto pull-right accordion-menu xs-no-padding-right">
                <button
                  type="button"
                  className="navbar-toggle collapsed pull-right"
                  data-toggle="collapse"
                  data-target="#navbar-collapse-toggle-1"
                >
                  <span className="sr-only">toggle navigation</span>
                  <span className="icon-bar" />
                  <span className="icon-bar" />
                  <span className="icon-bar" />
                </button>
                <div
                  className="navbar-collapse collapse pull-right"
                  id="navbar-collapse-toggle-1"
                >
                  <ul
                    id="accordion"
                    className="nav navbar-nav navbar-left no-margin alt-font text-normal"
                    data-in="fadeIn"
                    data-out="fadeOut"

                  >
                    {/* start menu item */}
                    {/* <li className="dropdown megamenu-fw">
                      <NavLink to="/">Home</NavLink>
                    

                    </li>
                    <li className="dropdown megamenu-fw">
                      <NavLink  to="#about"
                        onClick={() => scrollToSection("about")}>   About Us</NavLink>
                

                    </li>
                    <li className="dropdown megamenu-fw">
                      <NavLink to="#founder" onClick={() => scrollToSection("founder")}>   Our Founder</NavLink>
                

                    </li>
                    <li className="dropdown megamenu-fw">
                      <NavLink   to="#forlaw"
                        onClick={() => scrollToSection("forlaw")}>  For Law Students</NavLink>
                    </li>
                    <li className="dropdown megamenu-fw">
                      <NavLink   to="/faq"
                        > FaQ</NavLink>
                    </li>
                    <li className="dropdown megamenu-fw">
                      <NavLink   to="/contact"
                        > CONTACT</NavLink>
                    </li> */}

                    <li className="dropdown megamenu-fw">
                      <NavLink to="/" style={{ color: scrollPosition > 750 ? 'black' : 'white' }}>Home</NavLink>
                    </li>
                    <li className="dropdown megamenu-fw" >
                      <NavLink to="#about" onClick={() => scrollToSection("about")} style={{ color: scrollPosition > 750 ? 'black' : 'white' }}>About Us</NavLink>
                    </li>
                    <li className="dropdown megamenu-fw" style={{ color: `${scrollPosition}` > 750 ? 'black' : 'white' }}>
                      <NavLink to="#founder" onClick={() => scrollToSection("founder")} style={{ color: scrollPosition > 750 ? 'black' : 'white' }}>Our Founder</NavLink>
                    </li>
                    <li className="dropdown megamenu-fw">
                      <NavLink to="#forlaw" onClick={() => scrollToSection("forlaw")} style={{ color: scrollPosition > 750 ? 'black' : 'white' }}>For Law Students</NavLink>
                    </li>
                    <li className="dropdown megamenu-fw" >
                      <NavLink to="/faq" style={{ color: scrollPosition > 750 ? 'black' : 'white' }}>FaQ</NavLink>
                    </li>
                    <li className="dropdown megamenu-fw" >
                      <NavLink to="/contact" style={{ color: scrollPosition > 750 ? 'black' : 'white' }}>CONTACT</NavLink>
                    </li>






                  </ul>
                </div>
              </div>
              <div className="col-md-2 col-xs-5 width-auto">
                <div className="header-searchbar">

                  {/* start search input */}
                  {/* <form
                    id="search-header"
                    method="post"
                    action="search-result.html"
                    name="search-header"
                    className="mfp-hide search-form-result"
                  >
                    <div className="search-form position-relative">
                      <button
                        type="submit"
                        className="fas fa-search close-search search-button"
                      />
                      <input
                        type="text"
                        name="search"
                        className="search-input"
                        placeholder="Enter your keywords..."
                        autoComplete="off"
                      />
                    </div>
                  </form> */}
                  {/* end search input */}
                </div>
                <div className="header-social-icon xs-display-none">
                  <a
                    href="https://www.facebook.com/"
                    title="Facebook"
                    target="_blank"
                  >
                    <i className="fab fa-facebook-f" aria-hidden="true" />
                  </a>
                  <a href="https://twitter.com/" title="Twitter" target="_blank">
                    <i className="fab fa-twitter" />
                  </a>
                  <a href="https://dribbble.com/" title="Dribbble" target="_blank">
                    <i className="fab fa-dribbble" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </nav>
        {/* end navigation */}
      </header>
      {/* <SliderSection /> */}
      <section className="no-padding one-third-screen position-relative wow fadeIn">
        <div className="opacity-medium bg-extra-dark-gray z-index-0" />
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 display-table one-third-screen page-title-large center-col">
              <div className="display-table-cell vertical-align-middle text-center">
                {/* start sub title */}
                <span className="display-block text-white opacity6 margin-10px-bottom alt-font">
                  We provide innovative solutions to expand business
                </span>
                {/* end sub title */}
                {/* start page title */}
                <h1 className="alt-font text-white font-weight-600 width-55 sm-width-80 xs-width-100 center-col no-margin-bottom">
                  Looking for a excellent business idea?
                </h1>
                {/* end page title */}
              </div>
            </div>
            <div className="down-section text-center">
              <a href="#section-down" className="inner-link">
                <i className="ti-arrow-down icon-extra-small text-white bg-deep-pink padding-15px-all xs-padding-10px-all border-radius-100" />
              </a>
            </div>
          </div>
        </div>
        <div className="swiper-auto-fade swiper-container z-index-minus2 position-absolute top-0 width-100 height-100">
          <div className="swiper-wrapper">
            {/* start slider item */}
            <div
              className="swiper-slide cover-background one-third-screen"
              style={{ backgroundImage: 'url("https://www.themezaa.com/html/pofo/images/homepage-option17-slider-01.jpg")' }}
            />
            {/* end slider item */}
            {/* start slider item */}
            <div
              className="swiper-slide cover-background one-third-screen"
              style={{ backgroundImage: 'url("https://www.themezaa.com/html/pofo/images/homepage-option17-slider-01.jpg")' }}
            />
            {/* end slider item */}
            {/* start slider item */}
            <div
              className="swiper-slide cover-background one-third-screen"
              style={{ backgroundImage: 'url("https://www.themezaa.com/html/pofo/images/homepage-option17-slider-01.jpg")' }}
            />
            {/* end slider item */}
          </div>
          <div className="swiper-pagination swiper-auto-pagination display-none" />
        </div>
      </section>
      <section
        id="about"
        className="wow fadeIn cover-background sm-no-background-img bg-medium-light-gray"
        style={{ backgroundImage: 'url("https://www.themezaa.com/html/pofo/images/homepage-option17-banner-bg.jpg")' }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 pull-right wow fadeIn">
              <div className="col-lg-12 col-md-12 sm-text-center xs-no-padding-lr last-paragraph-no-margin margin-60px-bottom sm-margin-30px-bottom">
                <span className="text-medium text-deep-pink alt-font margin-10px-bottom display-inline-block">
                  {/* Easy way to build perfect websites */}
                </span>
                <h5 className="alt-font text-extra-dark-gray font-weight-600">
                  About Us
                </h5>
                <p className="width-90 sm-width-100">
                  Are you a talented legal professional seeking new opportunities to advance your career? Look no further! Legal Eagle Recruiting is your trusted partner in navigating the dynamic legal landscape, connecting top-tier talent with prestigious law firms and corporate legal departments.
                </p>
                <p className="width-90 sm-width-100">
                  At Legal Eagle Recruiting, we excel in connecting legal professionals with their ideal career opportunities. With a vast repository of fast-expanding resumes, encompassing entry-level associates seeking a promising start and experienced lawyers exploring new horizons, we are adept at catering to diverse career aspirations. Our primary goal is to deliver unparalleled recruitment services to our clients. Our team comprises seasoned and proficient professionals specialising in sourcing legal talent across all tiers Pan India.
                </p>
              </div>
              {/* star feature box item */}

              {/* end feature box item */}
              {/* star feature box item */}
              {/* <div className="col-md-6 col-sm-6 last-paragraph-no-margin xs-no-padding-lr sm-text-center">
          <i className="icon-layers text-deep-pink icon-extra-medium margin-20px-bottom sm-margin-15px-bottom" />
          <div className="feature-content">
            <div className="alt-font font-weight-600 text-extra-dark-gray margin-5px-bottom">
              Live Website Builder
            </div>
            <p className="width-80 md-width-90 sm-width-100">
              Lorem Ipsum is simply text the printing and typesetting standard
              industry.
            </p>
          </div>
        </div> */}
              {/* end feature box item */}
            </div>
          </div>
        </div>
      </section>
      <section className="wow fadeIn animated"

        id="founder">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-8 center-col margin-eight-bottom text-center last-paragraph-no-margin">
              <h5 className="alt-font text-extra-dark-gray font-weight-600">
                Our founder
              </h5>
              <p className="width-80 center-col display-inline-block xs-width-100">
                At Legal Eagle Recruiting, we are dedicated to reshaping the legal landscape by connecting exceptional and talented candidates with leading law firms, corporate entities, lawyers and advocates with their own practices, and organisations seeking specialised legal expertise. We believe in the approach of personalised matchmaking, aligning not just qualifications but also values, ambitions and culture, fit to create a long-lasting and productive partnership. Our consultancy services expand beyond candidate placements. We provide consultative expertise on career guidance and strategic advice to both recruiters and candidates with a goal to foster mutual benefit and long-term relationships which transcend individual placements.
              </p>
            </div>
          </div>
          <div className="row equalize">
            {/* start features box item */}
            <div className="col-md-3 col-sm-6 col-xs-12 sm-margin-30px-bottom wow fadeInUp last-paragraph-no-margin">
              <div className="bg-white border-color-extra-medium-gray border-solid border-width-1 text-center padding-eighteen-tb border-radius-4 position-relative inner-match-height">
                <div className="display-inline-block margin-20px-bottom">
                  <i className="icon-tools text-white icon-round-small bg-deep-pink" />
                </div>
                <div className="alt-font text-extra-dark-gray font-weight-600 margin-10px-bottom">
                  Pixel Perfect Design
                </div>
                <p className="width-75 center-col">
                  Lorem Ipsum is simply text of the printing and typesetting industry.
                  Lorem Ipsum has been standard dummy.
                </p>
              </div>
            </div>
            {/* end feature box item */}
            {/* start features box item */}
            <div
              className="col-md-3 col-sm-6 col-xs-12 sm-margin-30px-bottom wow fadeInUp last-paragraph-no-margin"
              data-wow-delay="0.2s"
            >
              <div className="bg-white border-color-extra-medium-gray border-solid border-width-1 text-center padding-eighteen-tb border-radius-4 position-relative inner-match-height">
                <div className="display-inline-block margin-20px-bottom">
                  <i className="icon-layers text-white icon-round-small bg-deep-pink" />
                </div>
                <div className="alt-font text-extra-dark-gray font-weight-600 margin-10px-bottom">
                  Full Documentation
                </div>
                <p className="width-75 center-col">
                  Lorem Ipsum is simply text of the printing and typesetting industry.
                  Lorem Ipsum has been standard dummy.
                </p>
              </div>
            </div>
            {/* end feature box item */}
            {/* start features box item */}
            <div
              className="col-md-3 col-sm-6 col-xs-12 xs-margin-30px-bottom wow fadeInUp last-paragraph-no-margin"
              data-wow-delay="0.4s"
            >
              <div className="bg-white border-color-extra-medium-gray border-solid border-width-1 text-center padding-eighteen-tb border-radius-4 position-relative inner-match-height">
                <div className="display-inline-block margin-20px-bottom">
                  <i className="icon-scissors text-white icon-round-small bg-deep-pink" />
                </div>
                <div className="alt-font text-extra-dark-gray font-weight-600 margin-10px-bottom">
                  Reasonable Pricing
                </div>
                <p className="width-75 center-col">
                  Lorem Ipsum is simply text of the printing and typesetting industry.
                  Lorem Ipsum has been standard dummy.
                </p>
              </div>
            </div>
            {/* end feature box item */}
            {/* start features box item */}
            <div
              className="col-md-3 col-sm-6 col-xs-12 wow fadeInUp last-paragraph-no-margin"
              data-wow-delay="0.6s"
            >
              <div className="bg-white border-color-extra-medium-gray border-solid border-width-1 text-center padding-eighteen-tb border-radius-4 position-relative inner-match-height">
                <div className="display-inline-block margin-20px-bottom">
                  <i className="icon-lock text-white icon-round-small bg-deep-pink" />
                </div>
                <div className="alt-font text-extra-dark-gray font-weight-600 margin-10px-bottom">
                  User-friendly Admin
                </div>
                <p className="width-75 center-col">
                  Lorem Ipsum is simply text of the printing and typesetting industry.
                  Lorem Ipsum has been standard dummy.
                </p>
              </div>
            </div>
            {/* end feature box item */}
          </div>
        </div>
      </section>
      {/* <StartSection/> */}
      <section className="wow fadeIn" id="forlaw">
        <div className="container">
          <div className="row equalize sm-equalize-auto">
            <div className="col-md-5 col-sm-12 col-xs-12 text-center display-table sm-margin-50px-bottom wow fadeInLeft">
              <div className="display-table-cell vertical-align-middle">
                <img src="https://www.themezaa.com/html/pofo/images/homepage-9-parallax-img6.jpg" alt="" className="width-100" />
              </div>
            </div>
            <div
              className="col-md-7 col-sm-12 col-xs-12 display-table wow fadeInRight last-paragraph-no-margin"
              data-wow-delay="0.2s"
            >
              <div className="display-table-cell vertical-align-middle padding-eight-lr sm-text-center xs-no-padding width-100">
                <span className="text-deep-pink alt-font margin-10px-bottom sm-no-margin-bottom display-inline-block text-medium">
                  {/* Wide range of web development services */}
                </span>
                <h5 className="font-weight-600 alt-font text-extra-dark-gray">
                  For Law Students
                </h5>
                <p>
                  Give information about what all courses we offer and starter pack of Rs.4400 which includes all kinds of help a law student can get.  For additional help, please contact us or subscribe to our Starter Plan

                </p>
                {/* <a
            href="about-us-simple.html"
            className="btn btn-dark-gray btn-small text-extra-small border-radius-4 margin-30px-top"
          >
            <i
              className="fas fa-play-circle icon-very-small margin-5px-right no-margin-left"
              aria-hidden="true"
            />{" "}
            About Company
          </a> */}
              </div>
            </div>
          </div>
          <div className="divider-full bg-extra-light-gray margin-seven-bottom margin-eight-top" />
          <div className="row">
            {/* start feature box */}
            <div className="col-md-4 col-sm-4 col-xs-12 xs-margin-30px-bottom xs-text-center wow fadeInUp last-paragraph-no-margin">
              <div className="col-md-3 col-sm-4 col-xs-12 no-padding-left xs-no-padding-lr pull-left xs-margin-15px-bottom">
                <i className="icon-desktop text-medium-gray icon-extra-medium top-6" />
              </div>
              <div className="col-md-9 col-sm-8 col-xs-12 no-padding">
                <span className="margin-5px-bottom text-extra-dark-gray alt-font display-block font-weight-600 xs-margin-10px-bottom">
                  Modern Framework
                </span>
                <p className="width-75 md-width-100">
                  Lorem Ipsum is simply text the printing and typesetting standard
                  industry.
                </p>
              </div>
            </div>
            {/* end feature box */}
            {/* start feature box */}
            <div
              className="col-md-4 col-sm-4 col-xs-12 xs-margin-30px-bottom xs-text-center wow fadeInUp last-paragraph-no-margin"
              data-wow-delay="0.2s"
            >
              <div className="col-md-3 col-sm-4 col-xs-12 no-padding-left xs-no-padding-lr pull-left xs-margin-15px-bottom">
                <i className="icon-book-open text-medium-gray icon-extra-medium top-6" />
              </div>
              <div className="col-md-9 col-sm-8 col-xs-12 no-padding">
                <span className="margin-5px-bottom text-extra-dark-gray alt-font display-block font-weight-600 xs-margin-10px-bottom">
                  Web Interactive
                </span>
                <p className="width-75 md-width-100">
                  Lorem Ipsum is simply text the printing and typesetting standard
                  industry.
                </p>
              </div>
            </div>
            {/* end feature box */}
            {/* start feature box */}
            <div
              className="col-md-4 col-sm-4 col-xs-12 xs-text-center wow fadeInUp last-paragraph-no-margin"
              data-wow-delay="0.4s"
            >
              <div className="col-md-3 col-sm-4 col-xs-12 no-padding-left xs-no-padding-lr pull-left xs-margin-15px-bottom">
                <i className="icon-gift text-medium-gray icon-extra-medium top-6" />
              </div>
              <div className="col-md-9 col-sm-8 col-xs-12 no-padding">
                <span className="margin-5px-bottom text-extra-dark-gray alt-font display-block font-weight-600 xs-margin-10px-bottom">
                  Graphic Design
                </span>
                <p className="width-75 md-width-100">
                  Lorem Ipsum is simply text the printing and typesetting standard
                  industry.
                </p>
              </div>
            </div>
            {/* end feature box */}
          </div>
        </div>
      </section>
      {/* <Portfolio />
      <Information /> */}
      {/* <Parallax /> */}
      <SecondService />
      {/* <SecondParallax /> */}
      {/* <StartSection/> */}
      {/* <Advantage/>
      <Counter/>
      <Video/>
      <ClientsSlider/>
      <TestimonialSlider/>
      <LatestBlog/> */}
      <Footer />
    </>

  )
}

export default Header
