import React from 'react'
import Navbar from './Navbar'
import Footer from './Footer'

const HireTalent = () => {
  return (
    <div>
      <Navbar />
      <>
        <section className="no-padding one-third-screen position-relative wow fadeIn">
          <div className="opacity-medium bg-extra-dark-gray z-index-0" />
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 display-table one-third-screen page-title-large center-col">
                <div className="display-table-cell vertical-align-middle text-center">
                  {/* start sub title */}
                  <span className="display-block text-white opacity6 margin-10px-bottom alt-font">
                    We provide innovative solutions to expand business
                  </span>
                  {/* end sub title */}
                  {/* start page title */}
                  <h1 className="alt-font text-white font-weight-600 width-55 sm-width-80 xs-width-100 center-col no-margin-bottom">
                    Looking for a excellent business idea?
                  </h1>
                  {/* end page title */}
                </div>
              </div>
              <div className="down-section text-center">
                <a href="#section-down" className="inner-link">
                  <i className="ti-arrow-down icon-extra-small text-white bg-deep-pink padding-15px-all xs-padding-10px-all border-radius-100" />
                </a>
              </div>
            </div>
          </div>
          <div className="swiper-auto-fade swiper-container z-index-minus2 position-absolute top-0 width-100 height-100">
            <div className="swiper-wrapper">
              {/* start slider item */}
              <div
                className="swiper-slide cover-background one-third-screen"
                style={{ backgroundImage: 'url("http://placehold.it/1920x1080")' }}
              />
              {/* end slider item */}
              {/* start slider item */}
              <div
                className="swiper-slide cover-background one-third-screen"
                style={{ backgroundImage: 'url("http://placehold.it/1920x1080")' }}
              />
              {/* end slider item */}
              {/* start slider item */}
              <div
                className="swiper-slide cover-background one-third-screen"
                style={{ backgroundImage: 'url("http://placehold.it/1920x1080")' }}
              />
              {/* end slider item */}
            </div>
            <div className="swiper-pagination swiper-auto-pagination display-none" />
          </div>
        </section>
        {/* end page title section */}
        {/* start contact form section */}
        <section className="wow fadeIn big-section" id="section-down">
          <div className="container">
            <div className="row equalize sm-equalize-auto">
              <div className="col-md-6 col-sm-12 col-xs-12 sm-margin-30px-bottom wow fadeInLeft">
                <div className="padding-fifteen-all bg-light-gray border-radius-6 md-padding-seven-all xs-padding-30px-all height-100">
                  <span className="text-extra-dark-gray alt-font text-large font-weight-600 margin-25px-bottom display-block">
                    Hire Talent
                  </span>
                  <form id="contact-form" action="javascript:void(0)" method="post">
                    <div>
                      <div id="success-contact-form" className="no-margin-lr" />
                      <input
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Name*"
                        className="border-radius-4 bg-white medium-input"
                      />
                      <input
                        type="text"
                        name="email"
                        id="email"
                        placeholder="E-mail*"
                        className="border-radius-4 bg-white medium-input"
                      />
                      <input
                        type="text"
                        name="subject"
                        id="subject"
                        placeholder="Subject"
                        className="border-radius-4 bg-white medium-input"
                      />
                      <textarea
                        name="comment"
                        id="comment"
                        placeholder="Your Message"
                        rows={5}
                        className="border-radius-4 bg-white medium-textarea"
                        defaultValue={""}
                      />
                      <button
                        id="contact-us-button"
                        type="submit"
                        className="btn btn-small border-radius-4 btn-dark-gray"
                      >
                        send message
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-md-6 col-sm-12 col-xs-12 last-paragraph-no-margin wow fadeInRight">
                <div className="padding-ten-all bg-light-gray border-radius-6 md-padding-seven-all xs-padding-30px-all height-100 sm-text-center">
                  <img
                    src="http://placehold.it/1000x700"
                    alt=""
                    className="border-radius-6 margin-35px-bottom xs-margin-30px-bottom"
                  />
                  <span className="text-large font-weight-600 alt-font text-extra-dark-gray margin-5px-bottom display-block">
                    Let's plan for a new project?
                  </span>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and typesetting
                    industry, Lorem Ipsum has been the standard dummy text.
                  </p>
                  <a
                    href="about-us-modern.html"
                    className="btn btn-dark-gray btn-small text-extra-small border-radius-4 margin-25px-top"
                  >
                    About Company
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* end contact form section */}
        {/* start information section */}
    
        
      </>

      <Footer />

    </div>
  )
}

export default HireTalent
